import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';

const Home = (props) => {
  const intro = props.data.intro;
  const services = props.data.services.edges;
  const testimonials = props.data.testimonials.edges;
  const introImageClasses = `intro-image ${
    intro.frontmatter.intro_image_absolute && 'intro-image-absolute'
  } ${
    intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'
  }`;

  return (
    <Layout bodyClass="page-home">
      <SEO
        location={props.location}
        description="Personalized one-on-one tutoring in Toronto. Our experienced tutors have been helping students achieve their goals and get better grades for over 20 years."
      />

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start flex-wrap-reverse">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <Call showButton />
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <picture>
                  <source
                    srcSet={intro.frontmatter.intro_image_optimized}
                    type="image/webp"
                  />
                  <source
                    srcSet={intro.frontmatter.intro_image}
                    type="image/png"
                  />
                  <img
                    alt={intro.frontmatter.title}
                    className={introImageClasses}
                    src={intro.frontmatter.intro_image}
                  />
                </picture>
              </div>
            )}
          </div>
        </div>
      </div>

      {services.length > 0 && (
        <div className="strip intro-services">
          <div className="container pt-4 pb-4 pb-md-8">
            <div className="row justify-content-start">
              {services.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-4 mb-1">
                  <div className="service service-summary">
                    <div className="service-content">
                      <h2 className="service-title">
                        <Link to={node.frontmatter.slug}>
                          {node.frontmatter.title}
                        </Link>
                      </h2>
                      <p>{node.frontmatter.summary}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {testimonials.length > 0 && (
        <div className="strip strip-grey intro-testimonials">
          <div className="container pt-4 pb-4 pt-md-8 pb-md-8">
            <h2 className="title-secondary mb-4">Testimonials</h2>
            <div className="row justify-content-center">
              {testimonials.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                  <div className="feature">
                    <blockquote className="mb-0">
                      <p className="mb-1">{`"${node.comment}"`}</p>
                    </blockquote>
                    <div className="feature-content">
                      {'- '}
                      <strong className="text-primary">{node.name}</strong>
                      {', '}
                      <span>{node.role}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/.*/" } }
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            summary
            date(formatString: "DD MMMM YYYY")
            slug
          }
          excerpt
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        image
        intro_image
        intro_image_optimized
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    testimonials: allTestimonialsJson {
      edges {
        node {
          id
          comment
          name
          role
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
